import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="boxrename"
export default class extends Controller {
  static targets = ['form', 'filename', 'namefield']

  connect() {
  }

  edit() {
    console.log(this.namefieldTarget)
    this.filenameTarget.innerText = this.namefieldTarget.value;
    this.formTarget.classList.toggle('open');
  }
}
