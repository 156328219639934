import { Controller } from "@hotwired/stimulus"
// import { TurboConfirm } from "@rolemodel/turbo-confirm"

// Connects to data-controller="leaving-page"
export default class extends Controller {
  connect() {
    
    // this.tc = new TurboConfirm({
    //   dialogSelector: '#leaving-page-modal',
    //   activeClass: 'show',
    //   acceptSelector: '.leaving-accept',
    //   denySelector: '.leaving-deny',
    //   messageSlotSelector: '#leaving-message',
    // })

    // window.addEventListener('beforeunload', (event) => {
    //   // console.log('LEAVING')
    //   // this.tc.confirm()
    //   // event.preventDefault()
    //   // event.stopImmediatePropagation()
    // })

  }
}
