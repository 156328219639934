import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="email-risks-tasks"
export default class extends Controller {

  removals(){
    const projects = document.querySelector('#email_message_project_id')
    let project = projects.querySelectorAll('option')[projects.selectedIndex].text;

    let taskSelect = document.querySelector('#email_message_task_ids')
    let tasksAll = taskSelect.querySelectorAll('option')
    tasksAll.forEach(task => {
      task.style.display = "block"
      if(task.text.split('|', 1)[0] !== project && task.value !== null){
        task.style.display = "none"
      }
    });

    let riskSelect = document.querySelector('#email_message_risk_ids')
    let risksAll = riskSelect.querySelectorAll('option')
    risksAll.forEach(risk => {
      risk.style.display = "block"
      if(risk.text.split('|', 1)[0] !== project && risk.value !== null){
        risk.style.display = "none"
      }
    });
  }

  connect() {
    this.removals();
    document.querySelector('#email_message_project_id').addEventListener("change", () => {
      this.removals();
    })
  }
}
