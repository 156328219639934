import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bulk-remove"
export default class extends Controller {
  static targets = ["bulkCheckBox", "allCheckBox"]
  selectedIds = null
  clicked(event) {
    if (event.target.value === "all" && this.allCheckBoxTarget.checked) {
      this.bulkCheckBoxTargets.forEach(checkbox => {
        checkbox.checked = true
      })
    } else if (event.target.value === "all" && !this.allCheckBoxTarget.checked) {
      this.bulkCheckBoxTargets.forEach(checkbox => {
        checkbox.checked = false
      })
    } else {
      this.allCheckBoxTarget.checked = false
    }
  }

  navigateToBulkRemove(event) {
    event.preventDefault()

    if (this.allCheckBoxTarget.checked) {
      this.selectedIds = ["all"]
    } else {
      this.selectedIds = this.bulkCheckBoxTargets
        .filter(checkbox => checkbox.checked)
        .map(checkbox => checkbox.value).join(",")
    }

    if (this.selectedIds.length > 0) {
      const basePath = event.currentTarget.getAttribute("href")
      const newPath = `${basePath}?bulk_ids=${this.selectedIds}`
      fetch(newPath, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          Accept: 'text/vnd.turbo-stream.html',
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.head.querySelector('[name="csrf-token"]').content,
          'Turbo-Frame': 'add_active_process_module'
        }
      })
      .then((response) =>  response.text())
      .then(Turbo.renderStreamMessage)
    } else {
      alert("Please select at least one task.")
    }
  }

}