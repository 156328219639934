import { Controller } from "@hotwired/stimulus"
import Pikaday from 'pikaday';

// Connects to data-controller="pikaday"
export default class extends Controller {
  connect() {
    this.element.autocomplete = 'off';

    const picker = new Pikaday({
      field: this.element,
      format: 'M/D/YYYY',
      minDate: new Date(2023, 1, 1),
      maxDate: new Date(2100, 1, 1),
    });

  }
}
