import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="promap-loading"
export default class extends Controller {
  connect() {
    const buildStartNotif = '<div id="flash"><div class="flash alert">Building your Process Map</div></div>';
    const buildEndNotif = '<div id="flash"><div class="flash alert">Building complete</div></div>';
    let flashCont = document.querySelector('turbo-frame#flash');

    this.buildStart(flashCont, buildStartNotif)
    this.buildEnd(flashCont, buildEndNotif)
    this.submitBtnHandling(flashCont, buildStartNotif)
  }

  buildStart(flashCont, buildStartNotif) {
    document.addEventListener('turbo:submit-start', () => {
      flashCont.insertAdjacentHTML("beforeend", buildStartNotif);
    });
  }

  buildEnd(flashCont, buildEndNotif) {
    document.addEventListener('turbo:submit-end', () => {
      flashCont.insertAdjacentHTML("beforeend", buildEndNotif);
    });
  }

  submitBtnHandling(flashCont, buildStartNotif) {
    let submitBtns = document.getElementsByClassName('btn--primary');
    [...submitBtns].forEach(btn => {
      if(btn.type == "submit"){
        btn.addEventListener("click", () => {
          flashCont.insertAdjacentHTML("beforeend", buildStartNotif);
        })
      }
    });
  }
}
