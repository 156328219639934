import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    Turbo.setConfirmMethod((message, element) => {
      let dialog = document.getElementById("turbo-confirm")
      dialog.querySelector("h3").textContent = message
      dialog.showModal()
    
      return new Promise((resolve, reject) => {
        dialog.addEventListener("close", () => {
          resolve(dialog.returnValue == "confirm")
        }, { once: true })
      })
    })
  }
}