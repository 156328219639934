import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button"]

  connect() {
    this.restoreStates();
    this.setupUnloadEvent();
    this.expandSpecifiedCategory();
    this.expandSpecifiedSubCategory();
  }

  expandSpecifiedSubCategory() {
    const expandedSubCategoryId = this.element.dataset.costexpandExpandedSubCategoryId;
    if (expandedSubCategoryId) {
      const subCategoryElement = document.querySelector(`[data-sub-category-id="${expandedSubCategoryId}"]`);
      if (subCategoryElement && subCategoryElement.classList.contains('close')) {
        this.toggleElement(subCategoryElement);
      }
    }
  }

  expandSpecifiedCategory() {
    const expandedCategoryId = this.element.dataset.costexpandExpandedCategoryId;
    if (expandedCategoryId) {
      const categoryElement = document.querySelector(`[data-category-id="${expandedCategoryId}"]`);
      if (categoryElement && categoryElement.classList.contains('close')) {
        this.toggleElement(categoryElement);
      }
    }
  }

  toggle(e) {
    const categoryElement = e.currentTarget;
    const categoryId = categoryElement.dataset.categoryId;
    const currentState = categoryElement.classList.contains('close') ? 'expanded' : 'collapsed';
  
    // Toggle the category
    this.toggleElement(categoryElement);

    // Toggle all subcategories within this category
    const subCategoryElements = document.querySelectorAll(`[data-category-id="${categoryId}"][data-sub-category-id]`);
    subCategoryElements.forEach(subCategoryElement => {
      if ((currentState === 'expanded' && subCategoryElement.classList.contains('close')) || 
          (currentState === 'collapsed' && !subCategoryElement.classList.contains('close'))) {
        this.toggleElement(subCategoryElement);
      }
    });
  }

  toggleElement(element) {
    const categoryId = element.dataset.categoryId;
    const currentState = element.classList.contains('close') ? 'expanded' : 'collapsed';

    element.classList.toggle('close');
    element.nextElementSibling.classList.toggle('close');
    this.updateCookie(categoryId, currentState);
  }

  updateCookie(categoryId, state) {
    const cookieName = 'categoryState';
    let categoryState = this.getCookie(cookieName) ? JSON.parse(this.getCookie(cookieName)) : {};
    categoryState[categoryId] = state;
    document.cookie = `${cookieName}=${JSON.stringify(categoryState)};path=/`;
  }

  getCookie(name) {
    let cookieValue = document.cookie.split('; ').find(row => row.startsWith(name + '='));
    return cookieValue ? decodeURIComponent(cookieValue.split('=')[1]) : null;
  }

  setupUnloadEvent() {
    window.addEventListener('beforeunload', () => {
      document.cookie = 'categoryState=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    });
  }

  restoreStates() {
    const cookieName = 'categoryState';
    let categoryState = this.getCookie(cookieName) ? JSON.parse(this.getCookie(cookieName)) : {};
    document.querySelectorAll('[data-action="click->costexpand#toggle"]').forEach(element => {
      const categoryId = element.dataset.categoryId;
      const state = categoryState[categoryId];
      if (state === 'expanded') {
        element.classList.remove('close');
        element.nextElementSibling.classList.remove('close');
      } else if (state === 'collapsed') {
        element.classList.add('close');
        element.nextElementSibling.classList.add('close');
      }
    });
  }

  toggleall(e) {
    let headers = [...document.querySelectorAll('.cost-table__top')];
    let bodys = [...document.querySelectorAll('.cost-table__body')];
    let elements = [].concat(headers, bodys);

    elements.forEach(el => {
      if(e.currentTarget.innerText === 'Expand All'){
        el.classList.remove('close');
      } else if (e.currentTarget.innerText === 'Close All'){
        el.classList.add('close');
      }
    });

    e.currentTarget.innerText = e.currentTarget.innerText === 'Expand All' ? 'Close All' : 'Expand All';
  }
}
