Turbo.StreamActions.show_remote_modal = function () {
  const container = document.querySelector("remote-modal-container");
  container.replaceChildren(this.templateContent);

  const dialog = container.querySelector("dialog");

  // Show the dialog
  dialog.showModal();

  // Add event listener for clicking outside the modal content
  dialog.addEventListener('click', function (event) {
    const dialogContent = dialog.querySelector('.dialog__container');
    if (!dialogContent.contains(event.target)) {
      dialog.close(); // Close the dialog if clicked outside content
    }
  });
};
