import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hideshow"
export default class extends Controller {
  static targets = ['el', 'btn'];

  connect() {
    let el = this.elTarget;
    let btn = this.btnTarget;

    document.addEventListener("click", (e)=>{
      let c1 = el.contains(e.target);    
      let c2 = btn.contains(e.target);    
      if(!c1 && !c2){        
        if(el.classList.contains('open')){
          el.classList.remove('open')
        }
      }
    })
  }

  toggle() {
    let el = this.elTarget;
    
    el.classList.toggle('open');
    
  }

}
