import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="costs-form"
export default class extends Controller {
  static values = { projectId: Number }
  // static targets = ["subCategory - module", "categoryBreakdown -step", "lineItem", "contractBreakdown"]
  static targets = ["phase", "processModule", "processStep"]
  connect() {
  }

  phaseValueChanged(event) {
    let params = new URLSearchParams()
    let selectBoxes = document.getElementById('task-phase-form-selects');
    params.append("target", this.processModuleTarget.id)
    let phaseId = event.target.selectedOptions[0].value

    if(this.processStepTarget) {
      params.append("child1", this.processStepTarget.id)
    }

    selectBoxes.classList.remove('hidden');

    get(`/api/phases/${phaseId}/process_modules?${params}`, {
      responseKind: "turbo-stream"
    })
  }

  processModuleValueChanged(event) {
    let params = new URLSearchParams()
    params.append("target", this.processStepTarget.id)
    let processModuleId = event.target.selectedOptions[0].value

    get(`/api/process_modules/${processModuleId}/process_steps?${params}`, {
      responseKind: "turbo-stream"
    })
  }
}
