import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="simple-expnd"
export default class extends Controller {
  connect() {
    console.log(this.element)
  }
  toggle() {
    this.element.classList.toggle('closed')
  }
}
