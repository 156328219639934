import html2canvas from 'html2canvas';
import {jsPDF} from 'jspdf';
import moment from 'moment';

export default () => {

  const exportBtn = document.querySelector('.costspdf');

  if(!exportBtn){ return };

  const handleDownloadPdf = async () => {

    const printRef = document.querySelector('.page__centre');

    var HTML_Width = printRef.offsetWidth;
    var HTML_Height = printRef.offsetHeight;

    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;
    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

    // https://github.com/niklasvh/html2canvas/issues/1856
    const canvas = await html2canvas(printRef, {
      allowTaint: true,
      useCORS: true,
      logging: false,
      imageTimeout: 5000,
      scale:2,
      onclone: (clonedElement) => {
        clonedElement.querySelectorAll('[class*="shadow"]').forEach((elem) => {
          removeClassByPrefix(elem, 'shadow')
        });
      }
    });

    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);

    pdf.addImage(data, 'PNG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);


    for (var i = 1; i <= totalPDFPages; i++) {
      pdf.addPage(PDF_Width.toString(), PDF_Height.toString());
      pdf.addImage(data, 'PNG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
    }

    let title = document.querySelector(".active.panel__title")

    pdf.save(`${title.innerHTML}-${moment().format('l')}.pdf`);
  };

  exportBtn.addEventListener('click', () => {
    const page = document.querySelector('.page__centre');
    page.classList.add('pdf')
    handleDownloadPdf();
    page.classList.remove('pdf')
  })


}