import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="file-explorer-drag-drop"
export default class extends Controller {
  connect() { 
  }

  containingDiv(startElement) {
    while (startElement && startElement.tagName !== 'DIV') {
      startElement = startElement.parentElement;
    }
    return startElement
  }

  dragenter(event) {
    const target = this.containingDiv(event.target)
    target.classList.add("over")
  }

  dragleave(event) {
    const target = this.containingDiv(event.target)
    target.classList.remove("over")
  }

  dragstart(event) {
    event.dataTransfer.effectAllowed = "move"
    event.dataTransfer.setData('text/plain', this.element.dataset.draggedIdValue)
  }

  dragover(event) {
    event.preventDefault()
    const target = this.containingDiv(event.target)
    target.classList.add("over")
  }

  drop(event) {
    const droppableClass = "droppable" 
    const target = this.containingDiv(event.target)

    if (!target.classList.contains(droppableClass)) {
      return
    } 

    event.preventDefault()
    target.classList.remove("over")

    var draggedIdValue = event.dataTransfer.getData("text/plain")
    var dropTargetIdValue = target.dataset.targetIdValue

    console.log(`Moving file ${draggedIdValue} to folder ${dropTargetIdValue}`)

    fetch(`/storage/files/${draggedIdValue}/move-to-folder`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.head.querySelector('[name="csrf-token"]').content,
        'Turbo-Frame': ':storage_folder_view'
      },
      body: JSON.stringify({ folder_id: dropTargetIdValue })
    })
    .then((response) =>  response.text())
    .then(Turbo.renderStreamMessage)
  }
}
