import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="task-form"
export default class extends Controller {
  connect() {
    let select = this.element.querySelector('#task_project_id');
    let content = this.element.querySelector('.add-new-task__form-content');
    open = () => {
      content.classList.add('open');
    };
    close = () => {
      content.classList.remove('open');
    };
    if(select){      
      select.addEventListener("change", () => {
        if(select.value > 0){
          open();
        } else {
          close();
        }
      })
    }

    let editForm = this.element.querySelector('.simple_form');
    const pageRight = document.querySelector('.page__right');

    if(editForm.classList.contains('edit_invoice') && pageRight.classList.contains('close')){
      pageRight.classList.remove('close');
    } else 
    if(editForm.classList.contains('edit_contract_breakdown') && pageRight.classList.contains('close')){
      pageRight.classList.remove('close');
    }
  }
}
