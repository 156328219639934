import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="progress"
export default class extends Controller {

  static targets = ["progbar"]

  connect() {
    let progress = this.progbarTarget.getAttribute('data-progress');
    let completed = this.progbarTarget.getAttribute('data-completed');
    let behindSchedule = this.progbarTarget.getAttribute('data-behind-schedule');
    let underBudget = this.progbarTarget.getAttribute('data-under-budget');
    
    this.progbarTarget.style.width = progress + "%";
    if(underBudget == 'false'){
      this.progbarTarget.style.backgroundColor = 'rgb(224 0 0)';
    } else if(behindSchedule == 'true'){
      this.progbarTarget.style.backgroundColor = 'rgb(224 0 0)';
    } else if(completed == 'true' ){
      this.progbarTarget.style.backgroundColor = '#38a390';
    } else {
      if(progress > 100){
        this.progbarTarget.style.backgroundColor = 'rgb(224 0 0)';
      } else {
        this.progbarTarget.style.backgroundColor = '#38a390';
      }
    }
  }
}
