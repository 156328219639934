import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tasks-header"
export default class extends Controller {

  static targets = ['mytasks']

  connect() {
    if (window.location.href.indexOf("my-critical") != -1){
      this.mytasksTarget.classList.add('active');
    }
  }
}
