import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="email-filters"
export default class extends Controller {
  static emails
  static btns

  connect() {
    this.emails = document.querySelectorAll('.correspondence__email')
    this.btns = document.querySelectorAll('.correspondence__filters > li')

  }

  inbox(btn) {
    this.emails.forEach(email => {
      if(email.classList.contains('hide')){
        email.classList.remove('hide');
      }
      if(email.dataset.sentValue !== "received"){
        email.classList.add('hide')
      }
    });
    this.btns.forEach(e => {
      if(e.classList.contains('active')){
        e.classList.remove('active');
      }
    });
    btn.currentTarget.classList.add('active');
  }
  
  sent(btn) {
    this.emails.forEach(email => {
      if(email.classList.contains('hide')){
        email.classList.remove('hide');
      }
      if(email.dataset.sentValue !== "sent"){
        email.classList.add('hide')
      }
    });
    this.btns.forEach(e => {
      if(e.classList.contains('active')){
        e.classList.remove('active');
      }
    });
    btn.currentTarget.classList.add('active');
  }

  unread(btn) {
    this.emails.forEach(email => {
      if(email.classList.contains('hide')){
        email.classList.remove('hide');
      }
      if(email.dataset.readValue !== "unread"){
        email.classList.add('hide')
      }
    });
        
    this.btns.forEach(e => {
      if(e.classList.contains('active')){
        e.classList.remove('active');
      }
    });
    btn.currentTarget.classList.add('active');
  }

  read(btn) {
    this.emails.forEach(email => {
      if(email.classList.contains('hide')){
        email.classList.remove('hide');
      }
      if(email.dataset.readValue !== "read"){
        email.classList.add('hide')
      }
    });
        
    this.btns.forEach(e => {
      if(e.classList.contains('active')){
        e.classList.remove('active');
      }
    });
    btn.currentTarget.classList.add('active');
  }

}
