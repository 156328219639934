import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="costs-form"
export default class extends Controller {
  static values = { projectId: Number }
  static targets = ["subCategory", "categoryBreakdown", "lineItem", "contractBreakdown"]
  connect() {
  }

  categoryValueChanged(event) {
    let params = new URLSearchParams()
    params.append("target", this.subCategoryTarget.id)
    let categoryId = event.target.selectedOptions[0].value

    if(this.categoryBreakdownTarget) {
      params.append("child1", this.categoryBreakdownTarget.id)
    }
    if(this.lineItemTarget) {
      params.append("child2", this.lineItemTarget.id)
    }
    if(this.contractBreakdownTarget) {
      params.append("child3", this.contractBreakdownTarget.id)
    }

    get(`/api/categories/${categoryId}/sub_categories?${params}`, {
      responseKind: "turbo-stream"
    })
  }

  subCategoryValueChanged(event) {
    let params = new URLSearchParams()
    params.append("target", this.categoryBreakdownTarget.id)
    let subCategoryId = event.target.selectedOptions[0].value

    if(this.lineItemTarget) {
      params.append("child1", this.lineItemTarget.id)
    }
    if(this.contractBreakdownTarget) {
      params.append("child2", this.contractBreakdownTarget.id)
    }

    get(`/api/sub_categories/${subCategoryId}/category_breakdowns?${params}`, {
      responseKind: "turbo-stream"
    })
  }

  // For Contract Breakdown Form && Invoice Form
  categoryBreakdownValueChanged(event) {
    let params = new URLSearchParams()
    params.append("target", this.lineItemTarget.id)
    params.append("project", this.projectIdValue)
    let categoryBreakdownId = event.target.selectedOptions[0].value

    if(this.contractBreakdownTarget) {
      params.append("child1", this.contractBreakdownTarget.id)
    }

    get(`/api/category_breakdowns/${categoryBreakdownId}/line_items?${params}`, {
      responseKind: "turbo-stream"
    })
  }

  // For Invoice
  lineItemValueChanged(event) {
    let params = new URLSearchParams()
    params.append("target", this.contractBreakdownTarget.id)
    params.append("project", this.projectIdValue)
    let lineItemId = event.target.selectedOptions[0].value

    get(`/api/line_items/${lineItemId}/contract_breakdowns?${params}`, {
      responseKind: "turbo-stream"
    })
  }
}
