import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { get } from "@rails/request.js"
import Pikaday from 'pikaday';
import moment from 'moment';

// Connects to data-controller="calendar-large"
export default class extends Controller {
  static targets = ["calendar"]
  static values = { url: String }

  connect() {
    const pageRight = document.querySelector('.page__right');
    pageRight.classList.add('close');

    let events
    async function getJson(urlValue){
      const response = await get(urlValue, {});
      const json = await response.json;
      return json
    };

    getJson(this.urlValue).then(response => {
      events = response;

      let calLarge = new Calendar(this.calendarTarget, {
        plugins: [ dayGridPlugin, timeGridPlugin ],
        initialView: 'dayGridMonth',
        eventDisplay:['block'],
        customButtons: null,
        // customButtons:{
        //   addEvent: {
        //     text: 'Add Event',
        //     click: function(){
        //       const pageRight = document.querySelector('.page__right');
        //       pageRight.classList.toggle('close');
        //     }
        //   }
        // },
        headerToolbar: {
          left: 'title',
          center: 'prev next',
          right: 'dayGridMonth,timeGridWeek,timeGridDay today addEvent'
        },
        events: events
      });
      calLarge.render();

      let dateInput = document.querySelector('#cal-pika-1');
      dateInput.addEventListener('change', () => {
        calLarge.gotoDate(dateInput.value);
      });

    });

    // PIKADAY CALENDARS

    let smallCal1 = new Pikaday({
      field: document.getElementById('cal-pika-1'),
      bound: false,
      container: document.getElementById('calendar-pikaday__container1'),
      format: 'YYYY-MM-DD',
      minDate: new Date(2023, 1, 1),
      maxDate: new Date(2100, 1, 1),
    });    

  }
}
