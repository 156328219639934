import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "selectAllButton"];

  toggleAll() {
    const shouldCheck = !this.allChecked();
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = shouldCheck;
    });

    this.updateButtonText();
  }

  allChecked() {
    return this.checkboxTargets.every(checkbox => checkbox.checked);
  }

  updateButtonText() {
    this.selectAllButtonTarget.textContent = this.allChecked() ? "Select None" : "Select All";
  }
}
