import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="boxdev-upload-btn"
export default class extends Controller {
  connect() {

  }

  toggle() {
    let modal = document.querySelector('.boxdev-uploader');
    modal.classList.toggle('open');

    let closeBtn = document.querySelector('.bcu-footer-left');
    closeBtn.addEventListener('click', () => {
      modal.classList.remove('open');
    })
  }
}
