import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { get } from "@rails/request.js"


// Connects to data-controller="calendar"
export default class extends Controller {

  static targets = ["monthly", "daily"]
  static values = { url: String }

  connect() {

    let events

    async function getJson(urlValue){
      const response = await get(urlValue, {});
      const json = await response.json;
      return json
    };

    getJson(this.urlValue).then(response => {
      events = response;

      let daily = new Calendar(this.dailyTarget, {
        plugins: [ dayGridPlugin, timeGridPlugin, listPlugin ],
        initialView: 'timeGridDay',
        headerToolbar: {
          left: 'title',
          right: 'today prev,next'
        },
        events: events
      });

      let monthly = new Calendar(this.monthlyTarget, {
        plugins: [ dayGridPlugin, timeGridPlugin, listPlugin ],
        initialView: 'dayGridMonth',
        headerToolbar: {
          left: 'prev',
          center: 'title',
          right: 'next'
        },
        events: events
      });

      daily.render();
      monthly.render(); 
          
    });
    
  }
}
