import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="promod-buttons"
export default class extends Controller {

  static targets = ['buttons'];

  connect() {
    let lock = document.querySelector('.lock-map')
    if(lock){
      if(lock.classList.contains('unlock')){
        let buttons = document.querySelectorAll('.module-single');
        buttons.forEach(e => {
          e.style.backgroundColor = 'transparent';
        });
      }
    }
  }

  active() {
    let buttons = document.querySelectorAll('.module-single.active');
    buttons.forEach(e => {
      e.classList.remove('active');
    });
    this.buttonsTarget.classList.add('active');
  }
}
