import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="riskindicator"
export default class extends Controller {

  static targets = ["indicator", "rank"]

  connect() {
    let span = this.indicatorTarget.querySelector('span.risk-indicator')
    let rank = this.rankTarget.innerText

    if(rank == 'high' || rank == 'High'){
      span.classList.add('high')
    } else if(rank == 'medium' || rank == 'Medium'){
      span.classList.add('med')
    } else if(rank == 'low' || rank == 'Low'){
      span.classList.add('low')
    }    
  }

}
