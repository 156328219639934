import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="openwith-swap"
export default class extends Controller {
  connect() {

    console.log('STIMULUS')

    let openwith
    let link = 'https://google.com'
    let newBtn = `<button class="btn"><span class="btn-content"><svg class="icon-open-with bcow-integration-icon" height="26" viewBox="0 0 16 16" width="26" focusable="false" aria-hidden="true" role="presentation"><path class="fill-color" fill="#4e4e4e" fill-rule="evenodd" d="M12 2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1H4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zm-1 2v6h-1V6.71l-4.65 4.64-.7-.7L9.29 6H5V5h6z"></path></svg><span class="bcow-btn-header-text">Open</span></span></button>`
    let preview = document.querySelector('.bcpr-PreviewHeader-controls');

    // let observer = new MutationObserver(function(mutations) {   
    //   console.log('I changed')
    //   if(preview){
    //     preview.insertAdjacentHTML('afterbegin', newBtn)
    //   }
    // }); 

    // observer.observe(this.element, { 
    //   attributes: false, 
    //   characterData: false, 
    //   childList: true, 
    //   subtree: true 
    // });

  }
}
