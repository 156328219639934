import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="comments-children"
export default class extends Controller {
  static targets = ["children", "hide", "show"]

  connect() {
    this.childrenTarget.classList.add("hidden")
    this.hideTarget.classList.add("hidden")
  }

  hide(event) {
    event.preventDefault()
    this.childrenTarget.classList.add("hidden")
    this.showTarget.classList.remove("hidden")
    this.hideTarget.classList.add("hidden")
  }

  show(event) {
    event.preventDefault()
    this.childrenTarget.classList.remove("hidden")
    this.showTarget.classList.add("hidden")
    this.hideTarget.classList.remove("hidden")
  }

}
