import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scheditem"
export default class extends Controller {
  connect() {
    const pageRight = document.querySelector('.page__right');
    let phasesHeader = document.querySelector('.phases-header');

    if(!pageRight.classList.contains('close')){
      pageRight.classList.add('close');
    }

    if(phasesHeader){
      this.phasesGantClick();
    }

  }

  editTask() {
    const pageRight = document.querySelector('.page__right');
    const editTask = document.querySelector('.schedule-activity__edit');
    const newTask = document.querySelector('.schedule-activity__new');
    if(pageRight.classList.contains('close') && editTask.classList.contains('close') && newTask.classList.contains('close')){ // None are open
      pageRight.classList.remove('close');
      editTask.classList.remove('close');
    } else if(!pageRight.classList.contains('close') && editTask.classList.contains('close') && !newTask.classList.contains('close')){ // newTask Act is open
      newTask.classList.add('close');
      editTask.classList.remove('close');
    } else if(pageRight.classList.contains('close') && editTask.classList.contains('close') && !newTask.classList.contains('close')){ // editTask is open
      pageRight.classList.remove('close');
      newTask.classList.add('close');
      editTask.classList.remove('close');
    }
  }

  newTask() {
    const pageRight = document.querySelector('.page__right');
    const editTask = document.querySelector('.schedule-activity__edit');
    const newTask = document.querySelector('.schedule-activity__new');
    if(pageRight && editTask && newTask){      
      if(pageRight.classList.contains('close') && editTask.classList.contains('close') && newTask.classList.contains('close')){ // None are open
        pageRight.classList.remove('close');
        newTask.classList.remove('close');
      } else if(!pageRight.classList.contains('close') && editTask.classList.contains('close') && !newTask.classList.contains('close')){ // newTask Act is open
        pageRight.classList.add('close');
        newTask.classList.add('close');
      } else if(!pageRight.classList.contains('close') && !editTask.classList.contains('close') && newTask.classList.contains('close')){ // editTask is open
        editTask.classList.add('close');
        newTask.classList.remove('close');
      } else if(pageRight.classList.contains('close') && editTask.classList.contains('close') && !newTask.classList.contains('close')){ // editTask is open
        pageRight.classList.remove('close');
      } 
    }
  }

  phasesGantClick() {
    setTimeout(() => {
      let tableBody = this.element.closest('.cost-table__body')
      if(tableBody){
        let gant = tableBody.querySelector('div.base-item.vis-item');
        gant.addEventListener('click', () => {
          this.element.click();
        })
      }
    }, 2000);
  }
}
