import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="additem"
export default class extends Controller {
  connect() {
    let url = window.location.pathname.split("/").pop();
    if(url == 'edit'){
      return
    } else {
      const pageRight = document.querySelector('.page__right');
      pageRight.classList.add('close');
    }
  }

  toggle() {
    const pageRight = document.querySelector('.page__right');
    pageRight.classList.toggle('close');
  }

}
