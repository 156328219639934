import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="promap-phases"
export default class extends Controller {

  connect() {    
    let url = window.location.pathname.split("/").pop();
    let phaseID = this.element.href.split("/").pop();
    if(phaseID == url){
      let title = this.element.querySelector('.process-map-phases__content--title');
      if(title){
        title.classList.add('active');
      }
    } else{
      return
    }
  }
}
