import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="from-expand"
export default class extends Controller {

  static targets = ["cont", "button"]

  connect() {
    const modal = document.querySelector('.sender-modal');
    document.body.addEventListener("click", (e) => {
      const parentClicked = e.composedPath().includes(this.buttonTarget)
      const modalClicked = e.composedPath().includes(modal)
      if(!parentClicked && !modalClicked && modal.classList.contains('open')){
        this.toggle();
      }
    });
  }

  toggle() {
    this.contTarget.classList.toggle('open');
    this.buttonTarget.lastElementChild.classList.toggle('rotate');
  }

}
