import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="promap-lock"
export default class extends Controller {
  connect() {
    const turbo1 = document.querySelector('#add_module_task_form')
    const turbo2 = document.querySelector('#edit_step_task_form')
    if(turbo1 && turbo2){      
      if(turbo1.innerText == "" && turbo2.innerText == ""){
        const pageRight = document.querySelector('.page__right');
        pageRight.classList.add('close');
      }
    } else {
      return
    }
  }
}
