import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="docu-actions"
export default class extends Controller {
  static targets = ['target', 'btn']

  connect() {
    document.addEventListener("click", (e)=>{
      if(!this.targetTarget.contains(e.target)){
        if(!this.btnTarget.contains(e.target)){
          this.targetTarget.classList.remove('open');
        }
      }
    })
  }
  
  toggle() {
    this.targetTarget.classList.toggle('open');
  }
}
