import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="proj-sched"
export default class extends Controller {
  connect() {
    setTimeout(() => { 
      let gants = document.querySelectorAll('div.base-item.vis-item')
      let headers = document.querySelectorAll('.cost-table__top');
      gants.forEach(a => {
        for (const b of headers) {
          if (b.querySelector('h3').textContent.toLowerCase().includes(a.dataset.title.toLowerCase())) {
            a.addEventListener('click', () => {
              b.click();
            })
          }
        }
      });    
    }, 2000);
  }
}
