import execPdf from './execpdf';
import projectPdf from './projectpdf';
import boxSubmit from './box_submit';
import costsPdf from './costspdf';
import turboLoader from './turbo_loader';

(() => {
  document.addEventListener('turbo:load', () => {
    execPdf();
    projectPdf();
    boxSubmit();
    costsPdf();
    turboLoader();
	})
  
  // document.addEventListener('turbo:frame-load', () => {})
})();