import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="recurring-event"
export default class extends Controller {
  connect() {
    let check = document.querySelector('#calendars_event_recurring')

    check.addEventListener('change', () => {
      this.toggle();
    })
  }
  toggle() {
    this.element.classList.toggle('open');
  }
}
