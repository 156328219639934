import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["projectSelect", "assignee", "approver", "manager"]

  updateUsers() {
    const projectId = this.projectSelectTarget.value;
    fetch(`/tasks/project_users?project_id=${projectId}`)
      .then(response => response.json())
      .then(users => {
        console.log(users);
        console.log(`Has assignee target: ${this.hasAssigneeTarget}`);
        console.log(`Has approver target: ${this.hasApproverTarget}`);
        console.log(`Has manager target: ${this.hasManagerTarget}`);
        this.updateSelectOptions(this.assigneeTarget, users);
        this.updateSelectOptions(this.approverTarget, users);
        this.updateSelectOptions(this.managerTarget, users);
      });
  }

  updateSelectOptions(selectElement, users) {
    while (selectElement.options.length > 1) selectElement.remove(1);
    users.forEach(user => {
      const option = new Option(user.email, user.id);
      selectElement.add(option);
    });
  }
}


