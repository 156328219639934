import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="page-right-close"
export default class extends Controller {
  connect() {
    const pr = document.querySelector('.page__right');
    const editPhase = document.querySelector('.edit_phase')
    if(pr.innerHTML == "\n"){
      pr.classList.add('close');
    } else if(editPhase) {
      pr.classList.remove('close');
    }
  }
}
