import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="password-generator"
export default class extends Controller {
  static targets = [ "password", "passwordConfirmation" ]

  generate(event) {
    event.preventDefault()
    this.passwordTarget.value = this.randomPassword()
    this.passwordConfirmationTarget.value = this.passwordTarget.value
  }

  randomPassword() {
    const length = 12
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
    let password = ""
    for (let i = 0; i < length; i++) {
      password += charset.charAt(Math.floor(Math.random() * charset.length))
    }
    return password
  }
}
