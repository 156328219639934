import html2canvas from 'html2canvas';
import {jsPDF} from 'jspdf';
import moment from 'moment';

export default () => {

  const exportBtn = document.querySelector('.projectpdf');
  
  if(exportBtn){
    let btns = document.querySelectorAll('.btn--transparent')
    btns.forEach(btn => {
      if(!btn.classList.contains('.btn--pseudo')){
        btn.setAttribute('data-html2canvas-ignore','');
      }
    });
    let rightside = document.querySelector('.page__right');
    rightside.setAttribute('data-html2canvas-ignore','');
  };
  
  if(!exportBtn){ return };

  const handleDownloadPdf = async () => {

    let projectTitle = document.querySelector('h2.panel__title').innerHTML

    const printRef = document.querySelector('.page');

    var HTML_Width = printRef.offsetWidth;
    var HTML_Height = printRef.offsetHeight;

    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;
    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

    // https://github.com/niklasvh/html2canvas/issues/1856
    const canvas = await html2canvas(printRef, {
      allowTaint: true,
      useCORS: true,
      logging: false,
      imageTimeout: 5000,
      scale:2,
    });

    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);

    pdf.addImage(data, 'PNG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);


    for (var i = 1; i <= totalPDFPages; i++) {
      pdf.addPage(PDF_Width.toString(), PDF_Height.toString());
      pdf.addImage(data, 'PNG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
    }
    pdf.save(`${projectTitle}-${moment().format('l')}.pdf`);
  };

  exportBtn.addEventListener('click', () => {
    const page = document.querySelector('.page');
    page.classList.add('pdf')
    handleDownloadPdf();
    page.classList.remove('pdf')
  })


}