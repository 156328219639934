import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="emailexpand"
export default class extends Controller {
  static values = { messageid: String }

  connect() {
  }

  toggle(e) {
    e.currentTarget.classList.toggle('rotate');
    // e.currentTarget.nextElementSibling.classList.toggle('close');
    e.currentTarget.closest('.correspondence__email').querySelector('.correspondence__email--snippet').classList.toggle('open')
    this.markAsRead(e);
  }

  async markAsRead(e) {
    try {
      const url = "/correspondence/" + this.messageidValue + '/mark_as_read';
      console.log(url);
      const response = await fetch(url)
    } catch (error) {
      console.error(error);
    }
  }
}
