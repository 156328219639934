export default () => {
  window.addEventListener('load', () => {
    submitBtnHandling();
  })

  let turboFrames = document.querySelectorAll('turbo-frame');

  const observer = new MutationObserver(() => {
    submitBtnHandling();    
  })

  turboFrames.forEach(frame => {
    observer.observe(frame, {subtree: true, childList: true});
  });

  const buildStartNotif = '<div id="flash"><div class="flash alert">Building your Process Map</div></div>';
  const buildEndNotif = '<div id="flash"><div class="flash alert">Building complete</div></div>';

  const submitBtnHandling = () => {
    let submitBtns = document.getElementsByClassName('btn--primary');
    [...submitBtns].forEach(btn => {
      if(btn.type == "submit" && !btn.classList.contains('modal-btn')){
        btn.addEventListener("click", () => {
          document.body.classList.add('loading')
          if(document.querySelector('[data-controller="phase-pool-form"]')){
            document.querySelector('turbo-frame#flash').insertAdjacentHTML("beforeend", buildStartNotif);
          }
        })
      }
    });
  }

  document.addEventListener('turbo:submit-start', () => {
    document.body.classList.add('loading');
  });

  document.addEventListener('turbo:submit-end', () => {
    document.body.classList.remove('loading');
  });

};