import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="projectitemform"
export default class extends Controller {
  connect() {
    const riskForm = document.querySelector('.task-form');
    const taskForm = document.querySelector('.risk-form');
    let forms = [riskForm, taskForm]

    forms.forEach(form => {
      if(!form.classList.contains('close')){
        form.classList.add('close');
      };
    });

  }

  toggletask() {
    const riskForm = document.querySelector('.task-form');
    const taskForm = document.querySelector('.risk-form');

    riskForm.classList.toggle('close');

    if(!taskForm.classList.contains('close')){
      taskForm.classList.add('close');
    };
  }

  togglerisk() {
    const riskForm = document.querySelector('.task-form');
    const taskForm = document.querySelector('.risk-form');

    taskForm.classList.toggle('close');

    if(!riskForm.classList.contains('close')){
      riskForm.classList.add('close');
    };
  }
}
