import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="docu-modal"
export default class extends Controller {

  static targets = ['file', 'folder']

  file() {
    this.fileTarget.classList.toggle('closed')
  }
  folder() {
    this.folderTarget.classList.toggle('closed')
  }

}
