import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="processdropdown"
export default class extends Controller {

  static targets = ["h2"]

  connect() {
    let phaseName = document.querySelector('#phase_pool_name');

    if(phaseName && phaseName.value){
      this.h2Target.innerText = phaseName.value;
    }
  }
}
