export default () => {

  const input = document.querySelector('#box_search_searchterm');
  const button = document.querySelector('input[type=submit]')
  let timeout = null;

  if(input){
    input.addEventListener("keyup", () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        if(input.value !== ""){
          button.click();
        };
      }, 600);
    })
  }

};